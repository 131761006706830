import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  isLoggedin: false,
  showOrderModal: false,
  plan: "",
  userData: {},
}

function reducer(state, action) {
  switch (action.type) {
    case "LOG_IN": {
      return {
        ...state,
        isLoggedin: true,
        userData: action.payload.userData,
      }
    }
    case "LOG_OUT": {
      return {
        ...state,
        isLoggedin: false,
        userData: {},
      }
    }
    case "SHOW_ORDER_MODAL": {
      return {
        ...state,
        showOrderModal: true,
        plan: action.payload.plan,
      }
    }
    case "HIDE_ORDER_MODAL": {
      return {
        ...state,
        showOrderModal: false,
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
